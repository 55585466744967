import React from "react";

const Navbar = () => {
  return (
    <header className="w-full py-6 border-b border-[#5D18BF]/50  bg-gray-950 text-green-400 font-mono px-6 overflow-hidden fixed shadow-lg shadow-[#5D18BF]/50">
      <div className="flex space-x-1 items-end justify-center ">
        <h1 className="text-xl md:text-3xl text-center font-bold ">
          Solana LoungeAI
        </h1>
      </div>
    </header>
  );
};

export default Navbar;
