import React, { createContext, useContext, useState, useEffect } from "react";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [responses, setResponses] = useState([]);
  const [username, setUsername] = useState("");

  // Load data dari localStorage saat aplikasi dimuat
  useEffect(() => {
    const savedMessages = JSON.parse(localStorage.getItem("messages") || "[]");
    const savedResponses = JSON.parse(
      localStorage.getItem("responses") || "[]"
    );
    const savedUsername = localStorage.getItem("username");

    setMessages(savedMessages);
    setResponses(savedResponses);
    setUsername(savedUsername || "");
  }, []);

  // Simpan data ke localStorage setiap kali ada perubahan
  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
    localStorage.setItem("responses", JSON.stringify(responses));
    localStorage.setItem("username", username);
  }, [messages, responses, username]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        responses,
        setResponses,
        username,
        setUsername,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

// Custom hook untuk menggunakan context
export const useChat = () => useContext(ChatContext);
