import React, { useEffect, useState } from "react";
import { Connection, PublicKey } from "@solana/web3.js";

const TopHolders = () => {
  const [holdersTop10, setHoldersTop10] = useState([]);
  const [holdersTop50, setHoldersTop50] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalSupply, setTotalSupply] = useState(null);
  const [top10HoldersPercentage, setTop10HoldersPercentage] = useState(0);
  const [top50HoldersPercentage, setTop50HoldersPercentage] = useState(0);

  const tokenMintAddress = "2LLAx8WDJva9i9ig3DNxs7oCrtw81c7D6V33RzJMpump";

  useEffect(() => {
    const fetchTopHolders = async () => {
      setLoading(true);
      setError("");
      setHoldersTop10([]);
      setHoldersTop50([]);
      setTotalSupply(null);
      setTop10HoldersPercentage(0);
      setTop50HoldersPercentage(0);

      try {
        if (!tokenMintAddress) {
          setError("Token address is required");
          return;
        }

        const connection = new Connection(
          "https://mainnet.helius-rpc.com/?api-key=91adc8c5-f119-4980-960f-d752849f0a3e",
          "confirmed"
        );

        const tokenMint = new PublicKey(tokenMintAddress);

        // Get total supply of the token
        const tokenSupply = await connection.getTokenSupply(tokenMint);
        const totalTokenSupply = tokenSupply.value.uiAmount;
        setTotalSupply(totalTokenSupply);

        const largestAccounts = await connection.getTokenLargestAccounts(
          tokenMint
        );

        // Fetch and calculate Top 10 Holders
        const top10Holders = largestAccounts.value.slice(1, 11);
        const totalHeldByTop10 = top10Holders.reduce(
          (sum, account) => sum + (account.uiAmount || 0),
          0
        );
        const percentageTop10 = (
          (totalHeldByTop10 / totalTokenSupply) *
          100
        ).toFixed(2);
        setTop10HoldersPercentage(percentageTop10);

        const processedTop10 = top10Holders.map((account, index) => {
          const balance = account.uiAmount || 0;
          const percentage = ((balance / totalTokenSupply) * 100).toFixed(2);

          return {
            rank: index + 1,
            address: account.address.toBase58(),
            balance: balance.toLocaleString(),
            percentage: percentage,
          };
        });

        setHoldersTop10(
          processedTop10.sort(
            (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
          )
        );

        // Fetch and calculate Top 50 Holders
        const top50Holders = largestAccounts.value.slice(12, 50);
        const totalHeldByTop50 = top50Holders.reduce(
          (sum, account) => sum + (account.uiAmount || 0),
          0
        );
        const percentageTop50 = (
          (totalHeldByTop50 / totalTokenSupply) *
          100
        ).toFixed(2);
        setTop50HoldersPercentage(percentageTop50);

        const processedTop50 = top50Holders.map((account, index) => {
          const balance = account.uiAmount || 0;
          const percentage = ((balance / totalTokenSupply) * 100).toFixed(2);

          return {
            rank: index + 1,
            address: account.address.toBase58(),
            balance: balance.toLocaleString(),
            percentage: percentage,
          };
        });

        setHoldersTop50(
          processedTop50.sort(
            (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
          )
        );
      } catch (err) {
        console.error("Error fetching top holders:", err);
        setError(`Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchTopHolders();
  }, [tokenMintAddress]);

  return (
    <div className="p-6 bg-gray-950 text-green-400 rounded-lg shadow-lg pt-24">
      <h1 className="text-2xl font-bold mb-4">Top Holders</h1>

      {loading && (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-green-500"></div>
        </div>
      )}

      {error && (
        <div className="bg-red-900 text-red-300 p-3 rounded-md">
          <p>{error}</p>
        </div>
      )}

      {!loading && !error && totalSupply !== null && (
        <>
          <div className="mb-4 text-xs md:text-lg">
            <p>
              Contract Address
              <span className="text-white ml-8">:</span>
              <span className="text-white ml-2 mr-2">{tokenMintAddress} </span>
            </p>
            {totalSupply !== null && (
              <p>
                Total Supply
                <span className="text-white ml-16 ">
                  <span className="text-white ml-2 mr-2">:</span>
                  {totalSupply.toLocaleString()}
                </span>
              </p>
            )}
            <p>
              Top 10 Holders hold
              <span className="text-white ml-2">:</span>
              <span className="text-white ml-2 mr-2">
                {top10HoldersPercentage}%
              </span>
              {/* of total supply */}
            </p>
            <p className="space-x-2 flex">
              Top 50 Holders hold
              <span className="text-white ml-2">:</span>
              <span className="text-white ml-2 mr-2">
                {top50HoldersPercentage}%
              </span>
              {/* of total supply */}
            </p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-3"></h2>
            <div className="overflow-x-auto">
              <table className="w-full bg-gray-800 rounded-lg overflow-hidden text-xs md:text-lg">
                <thead className="bg-gray-700 ">
                  <tr>
                    <th className="p-1 text-cebter">Rank</th>
                    <th className="p-3 text-left">Address</th>
                    <th className="p-3 text-left pl-0 md:pl-20">Balance</th>
                    <th className="p-3 text-left pl-0 md:pl-8">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {holdersTop10.map((holder) => (
                    <tr
                      key={holder.address}
                      className="border-b border-gray-700 hover:bg-gray-700 transition duration-200 "
                    >
                      <td className="p-1 text-center">{holder.rank}</td>
                      <td className="p-3 w-1/3">
                        <a
                          href={`https://solscan.io/account/${holder.address}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-green-400 hover:underline"
                        >
                          <span className="hidden sm:block">
                            {holder.address}
                            {/* Full address visible on desktop */}
                          </span>
                          <span className="inline sm:hidden">
                            {holder.address.slice(0, 6)}...
                            {holder.address.slice(-4)}{" "}
                            {/* Truncated address visible on mobile */}
                          </span>
                        </a>
                      </td>
                      <td className="p-3 text-left pl-0 md:pl-20">
                        {holder.balance}
                      </td>
                      <td className="p-3 text-left md:pl-8">
                        {holder.percentage}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {!loading &&
        !error &&
        holdersTop10.length === 0 &&
        holdersTop50.length === 0 && (
          <div className="text-yellow-500 text-center">
            No token holders found for this address.
          </div>
        )}
    </div>
  );
};

export default TopHolders;
