// terupdate

import React, { useState, useEffect, useRef } from "react";
import { Send, Loader } from "lucide-react";
import axios from "axios";
import musicCu from "../assets/music/musiccu.mp3";
export const Contract_Address = "Contract Address";

const TerminalChat = () => {
  const [messages, setMessages] = useState([]);
  const [responses, setResponses] = useState([]); // Menyimpan semua respons sebelumnya
  const [username, setUsername] = useState("");
  const [input, setInput] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [ws, setWs] = useState(null);
  const [error, setError] = useState(""); // State untuk pesan error
  // const [userCount, setUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesEndRefChat = useRef(null);
  const audioRef = useRef(null); // Ref untuk audio
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";
  const [isMobile, setIsMobile] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const WS_URL = process.env.REACT_APP_BACK_API_URL;

  // Link detection regex
  const linkDetectionRegex =
    /https?:\/\/[^\s]+|www\.[^\s]+|\.[a-z]{2,}(\/[^\s]*)?/i;

  // Solana contract address regex (base58 format: 32-44 characters, alphanumeric)
  const solanaContractAddressRegex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;

  // Check if the device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // 768px or below is considered mobile
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBottomChat = () => {
    messagesEndRefChat.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    // Play music automatically only on mobile devices
    if (username && audioRef.current && isMobile) {
      audioRef.current.play(); // Play music if mobile
    }
  }, [username, isMobile]);

  useEffect(() => {
    scrollToBottom();
    scrollToBottomChat();
    if (!isConnected && username) {
      window.location.reload(); // Reload halaman jika isConnected false dan username ada
    }
  }, [messages, responses, isConnected, username]);

  useEffect(() => {
    const websocket = new WebSocket(WS_URL);

    websocket.onopen = () => {
      setIsConnected(false);
      console.log("Connected to WebSocket");
    };

    websocket.onclose = () => {
      setIsConnected(false);
      console.log("Disconnected from WebSocket");
    };

    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "clear") {
        setMessages([]);
      } else if (message.type === "exit") {
        // setUsername("");
        setIsConnected(false);
        window.location.reload();
      } else {
        setMessages((prev) => [...prev, message]);
      }
    };

    setWs(websocket);

    return () => {
      websocket.close();
    };
  }, []);

  const sendMessage = (type, content) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type,
          content,
          username: username,
          timestamp: new Date().toISOString(),
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (username && solanaContractAddressRegex.test(input.trim())) {
      setError("Don't shill here!");
      setTimeout(() => setError(""), 2000);
      setInput("");
      return;
    }

    // Check for links in the input
    if (username && linkDetectionRegex.test(input)) {
      setError("Links are not allowed!");
      setTimeout(() => setError(""), 2000);
      setInput("");
      return;
    }

    // Only apply delay and button disable when username exists
    if (username) {
      setIsSendDisabled(true);
      setCountdown(3); // Mulai hitungan mundur dari 3 detik

      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsSendDisabled(false); // Aktifkan kembali tombol
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    if (!input.trim()) return;

    if (!username) {
      if (/\s/.test(input)) {
        setError("Don't use spaces");
        setInput("");
        setTimeout(() => setError(""), 1500);
        return;
      }

      setUsername(input);
      sendMessage("join", input);
      setIsConnected(true);
      setError("");
    } else {
      if (input.startsWith("/")) {
        const command = input.slice(1).toLowerCase();
        if (command === "clear") {
          setMessages([]);
          setResponses([]); // Kosongkan juga respons jika /clear
        } else {
          sendMessage("command", command);
        }
      } else if (input.startsWith("ask")) {
        e.preventDefault();
        if (isLoading || !input.trim()) return;

        setIsLoading(true);

        try {
          const result = await axios.post(`${API_URL}/api/chat`, {
            input,
          });

          const botResponse = result.data.response || "Not Responding";

          // Simpan respons ke daftar
          setResponses((prevResponses) => [
            ...prevResponses,
            { id: Date.now(), content: botResponse },
          ]);

          setInput("");
        } catch (error) {
          console.error("Error fetching response:", error);

          // Tambahkan fallback response ke daftar
          setResponses((prevResponses) => [
            ...prevResponses,
            { id: Date.now(), content: "Not Responding" },
          ]);
        } finally {
          setIsLoading(false);
        }
      } else {
        sendMessage("message", input);
      }
    }
    setInput("");
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!input.trim()) return;

  //   setTimeout(async () => {
  //     if (!username) {
  //       if (/\s/.test(input)) {
  //         setError("Don't use spaces");
  //         setInput("");
  //         setTimeout(() => setError(""), 1500);
  //         return;
  //       }

  //       setUsername(input);
  //       sendMessage("join", input);
  //       setIsConnected(true);
  //       setError("");
  //     } else {
  //       if (input.startsWith("/")) {
  //         const command = input.slice(1).toLowerCase();
  //         if (command === "clear") {
  //           setMessages([]);
  //           setResponses([]); // Kosongkan juga respons jika /clear
  //         } else {
  //           sendMessage("command", command);
  //         }
  //       } else if (input.startsWith("ask")) {
  //         if (isLoading || !input.trim()) return;

  //         setIsLoading(true);

  //         try {
  //           const result = await axios.post(`${API_URL}/api/chat`, {
  //             input,
  //           });

  //           const botResponse = result.data.response || "Not Responding";

  //           // Simpan respons ke daftar
  //           setResponses((prevResponses) => [
  //             ...prevResponses,
  //             { id: Date.now(), content: botResponse },
  //           ]);

  //           setInput("");
  //         } catch (error) {
  //           console.error("Error fetching response:", error);

  //           // Tambahkan fallback response ke daftar
  //           setResponses((prevResponses) => [
  //             ...prevResponses,
  //             { id: Date.now(), content: "Not Responding" },
  //           ]);
  //         } finally {
  //           setIsLoading(false);
  //         }
  //       } else {
  //         sendMessage("message", input);
  //       }
  //     }
  //     setInput("");
  //   }, 5000); // Jeda 3 detik
  // };

  const getMessageStyle = (type) => {
    switch (type) {
      case "system":
        return "text-yellow-400";
      case "command":
        return "text-cyan-400";
      case "error":
        return "text-red-400";
      default:
        return "text-green-400";
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const commands = ["help", "clear", "users", "time", "exit"];
      if (input.startsWith("/")) {
        const partial = input.slice(1).toLowerCase();
        const match = commands.find((cmd) => cmd.startsWith(partial));
        if (match) {
          setInput(`/${match}`);
        }
      }
    }
  };

  const timeAgo = (timestamp) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(timestamp)) / 1000); // Selisih waktu dalam detik

    if (diffInSeconds < 60) {
      return `${diffInSeconds}s`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}D`;
    }
  };

  const handleCopyAddress = () => {
    navigator.clipboard
      .writeText(Contract_Address)

      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Auto-update timeAgo every second for each message timestamp
  const [timeStamps, setTimeStamps] = useState({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedTimes = messages.reduce((acc, msg) => {
        acc[msg.timestamp] = timeAgo(msg.timestamp);
        return acc;
      }, {});
      setTimeStamps(updatedTimes);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [messages]);

  return (
    // <div className="min-h-screen  text-green-400 p-4 font-mono flex items-center justify-center">
    <div className="font-mono pt-24">
      <audio ref={audioRef} src={musicCu} loop />
      <div
        onClick={handleCopyAddress}
        className="text-center cursor-pointer text-green-400 text-xs md:text-lg p-0.5 mx-6  bg-gray-500/20 rounded-lg mb-3 text-bold bg-gradient-to-r from-[#5D18BF] via-[#9945FF] to-[#14F195] hover:text-white"
      >
        <div className="bg-gray-950 hover:bg-gradient-to-r from-[#5D18BF] via-[#9945FF] to-[#14F195] py-1 md:py-0 rounded-lg shadow-lg shadow-[#5D18BF]/50">
          {Contract_Address}
        </div>
      </div>
      <div
        className={`h-[80vh]  text-green-400 px-4 font-mono ${
          username && isConnected ? "  " : "flex items-center justify-center "
        }`}
      >
        {/* {isConnected && username && (
        <div className="flex items-center justify-center gap-2 mb-4">
          <span className="w-3 h-3 rounded-full bg-green-400 animate-pulse"></span>
          <h1 className="text-md">{userCount} user connected</h1>
        </div>
      )} */}
        <div
          className="  text-green-400 font-mono flex flex-col-reverse md:flex-row"
          id="sidebar"
        >
          <div className="w-full mx-auto px-2">
            {isConnected && username && (
              <div className="bg-gradient-to-r from-[#5D18BF] via-[#9945FF] to-[#14F195] rounded-lg p-0.5 mb-2 shadow-lg shadow-[#5D18BF]/50">
                <div
                  id="form-chat"
                  className={` bg-gray-950 rounded-lg px-4 md:h-[65vh] overflow-y-auto  ${
                    responses.length > 0
                      ? "h-[40vh]"
                      : isLoading
                      ? "h-[40vh]" // Kondisi ketika sedang loading
                      : "h-[60vh]" // Default ketika tidak ada response dan tidak loading
                  }`}
                >
                  <div className="space-y-1 text-start py-3 ">
                    {messages.map((msg, index) => (
                      <div
                        // key={index}
                        key={`${index}-${msg.timestamp}`}
                        className={`flex items-start gap-2 ${getMessageStyle(
                          msg.type
                        )}`}
                      >
                        {msg.username && (
                          <div className="text-purple-400 font-semibold text-xs md:text-sm">
                            {msg.username}:
                          </div>
                        )}
                        <div className="whitespace-pre-wrap flex-1 text-xs md:text-sm">
                          {msg.content}
                        </div>
                        <div
                          key={`timestamp-${msg.timestamp}`}
                          className="text-gray-500 text-xs md:text-sm"
                        >
                          {timeStamps[msg.timestamp] || timeAgo(msg.timestamp)}
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRefChat} />
                  </div>
                </div>
              </div>
            )}
            {/* <div class="relative p-1 rounded-lg bg-gradient-to-r from-red-500 via-green-500 to-blue-500 animate-marquee-border bg-[length:200%_200%]">
              <div class="bg-white rounded-lg p-4">
                <p class="text-center">Marquee Border!</p>
              </div>
            </div> */}

            <form
              onSubmit={handleSubmit}
              className="flex flex-col sm:flex-row gap-2 w-full justify-center items-center p-0 "
            >
              {/* <div className="w-full bg-gradient-to-r from-[#5D18BF] via-[#9945FF] to-[#14F195] p-0.5 rounded-lg shadow-2xl"> */}
              {/* <div className="w-full bg-gradient-to-r from-[#5D18BF] via-[#9945FF] to-[#14F195] p-0.5 rounded-lg shadow-[0_0_10px_4px_rgba(93,24,191,0.7),0_0_20px_6px_rgba(153,69,255,0.5),0_0_30px_8px_rgba(20,241,149,0.3)]"> */}
              <div className="w-full bg-gradient-to-r from-[#5D18BF] via-[#9945FF] to-[#14F195] p-0.5 rounded-lg shadow-lg shadow-[#5D18BF]/50 ">
                <div
                  className={`flex-1 bg-black/50 rounded-lg flex items-center w-full py-0 border ${
                    error
                      ? "border-red-400"
                      : "border-2 border-transparent rounded-lg bg-gray-950 "
                  }`}
                >
                  <span className="text-cyan-400 px-3">
                    {username ? "$" : ">"}
                  </span>
                  <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="flex-1 bg-transparent p-3 outline-none text-green-400 text-xs md:text-lg "
                    placeholder={
                      error
                        ? error
                        : username && isConnected
                        ? "Type a message or /help for commands..."
                        : "Enter username..."
                    }
                    disabled={isLoading}
                    maxLength={username ? 250 : 15}
                    autoFocus
                  />
                  {/* Button: Show 'Send' or 'Loading' */}
                  <button
                    type="submit"
                    disabled={isLoading || isSendDisabled} // Disable the button while loading or if sending is disabled
                    className={`rounded-tr-lg rounded-br-lg px-3 py-2.5 md:py-4 font-semibold text-sm md:text-base transition-colors duration-200 ${
                      isLoading || isSendDisabled
                        ? "text-green-400 animate-pulse cursor-not-allowed"
                        : "hover:bg-green-400 hover:border-black hover:text-black"
                    }`}
                  >
                    {isLoading ? (
                      <Loader className="animate-spin h-5 w-5" />
                    ) : isSendDisabled ? (
                      <div className="text-sm">{countdown}s</div>
                    ) : (
                      <Send className="h-4 w-4 md:h-5 md:w-5" />
                    )}
                  </button>
                </div>
              </div>
            </form>

            <div className="mt-2 text-center  text-gray-500 text-xs md:text-sm">
              {isConnected ? (
                <span className="text-green-400">
                  ● Connected as {username}
                </span>
              ) : (
                <span className="text-red-400">○ Disconnected</span>
              )}
            </div>
          </div>

          {(isLoading || responses.length > 0) && username && isConnected && (
            <div
              className="w-full mx-auto px-2 lg:w-1/2 mb-4 "
              id="form-chat-bot"
            >
              <div className="bg-gradient-to-r from-[#5D18BF] md:from-[#14F195] via-[#9945FF] md:via-[#9945FF] to-[#14F195] md:to-[#5D18BF] p-0.5 rounded-lg shadow-lg shadow-[#5D18BF]/50">
                <div className="text-justify bg-black/50  rounded-lg ">
                  <div className="flex-1 space-y-1 md:space-y-3 w-full p-4 md:h-[65vh] h-[20vh] overflow-y-auto bg-gray-950 rounded-lg">
                    {responses.map((res) => (
                      <div
                        key={res.id}
                        className="text-green-400 text-xs md:text-sm border-b border-gray-800 pb-1"
                        ref={messagesEndRef}
                      >
                        {res.content}
                      </div>
                    ))}
                    {isLoading && (
                      <div className="text-yellow-400 animate-pulse text-xs md:text-sm">
                        Bot is typing...
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TerminalChat;
