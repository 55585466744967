import React, { useState, useEffect } from "react";

const SnowEffect = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    const createSnowflake = () => {
      return {
        id: Math.random(),
        x: Math.random() * window.innerWidth,
        y: -10,
        size: Math.random() * 2 + 1,
        speed: Math.random() * 5 + 1, // Kecepatan lebih cepat
      };
    };

    const animateSnowflakes = () => {
      setSnowflakes((prevFlakes) => {
        // Tambahkan lebih banyak snowflake
        const newFlakes =
          Math.random() < 0.3 ? [...prevFlakes, createSnowflake()] : prevFlakes;

        // Update posisi snowflakes
        return newFlakes
          .map((flake) => ({
            ...flake,
            y: flake.y + flake.speed,
          }))
          .filter((flake) => flake.y < window.innerHeight);
      });
    };

    const snowInterval = setInterval(animateSnowflakes, 30); // Interval lebih cepat
    return () => clearInterval(snowInterval);
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      {snowflakes.map((flake) => (
        <div
          key={flake.id}
          className="absolute rounded-full bg-white opacity-70"
          style={{
            left: `${flake.x}px`,
            top: `${flake.y}px`,
            width: `${flake.size}px`,
            height: `${flake.size}px`,
          }}
        />
      ))}
    </div>
  );
};

export default SnowEffect;
