import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Navigate,
} from "react-router-dom";
import Sidebar from "./pages/SidebarSol";
import Navbar from "./pages/Navbar";
import RoomChat from "./pages/TerminalChatSolMusic";
import Whale from "./pages/Whale";
import SnowEffect from "./pages/SnowEfect";

function App() {
  return (
    <Router>
      {/* Flexbox untuk layout utama */}
      <div className="flex flex-col h-screen bg-gray-950">
        <SnowEffect />
        {/* Navbar di bagian atas, pastikan navbar tidak bisa di-scroll */}
        <Navbar />

        {/* Konten utama dengan sidebar dan main content */}
        <div className="flex flex-1 overflow-hidden">
          {/* Sidebar yang tidak bisa di-scroll */}

          <Sidebar />

          {/* Main content area yang dapat di-scroll */}

          <div className="flex-1 overflow-auto ">
            <main>
              <Routes>
                <Route path="/" element={<RoomChat />} />
                <Route path="/whale" element={<Whale />} />
                {/* <Route path="/whale" element={<Navigate to="/" replace />} /> */}
              </Routes>
            </main>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
