import { useLocation } from "react-router-dom";
import { Loader, Play, Pause } from "lucide-react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLandmark } from "react-icons/fa";
import { HiMiniCheckBadge } from "react-icons/hi2";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import musicCu from "../assets/music/musiccu.mp3";
import { Contract_Address } from "./TerminalChatSolMusic";

const Sidebar = () => {
  const location = useLocation();
  const [price, setPrice] = useState(null);
  const [errorPrice, setErrorPrice] = useState(null);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioElement, setAudioElement] = useState(null);

  // Contoh audio (ganti dengan URL musik Anda)
  // const musicUrl =
  //   "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";

  useEffect(() => {
    const audio = new Audio(musicCu);
    audio.loop = true;
    setAudioElement(audio);

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarVisible(true);
      } else {
        setSidebarVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      audio.pause();
    };
  }, []);

  const toggleMusic = () => {
    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price",
          {
            params: {
              ids: "solana",
              vs_currencies: "usd",
            },
          }
        );
        setPrice(response.data.solana.usd);
        setErrorPrice(null);
      } catch (errorPrice) {
        setErrorPrice("Error fetching price");
        console.error("Error fetching price:", errorPrice);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 60000);
    return () => clearInterval(interval);
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const getButtonBgColor = (path) => {
    const isActive = location.pathname === path;
    return {
      backgroundColor: isActive
        ? "bg-gradient-to-r from-[#5D18BF] via-[#9945FF] to-[#14F195] shadow-lg shadow-[#5D18BF]/50"
        : "bg-gray-800/40",
      hoverClass: isActive
        ? ""
        : "hover:bg-gradient-to-r hover:from-[#5D18BF] hover:via-[#9945FF] hover:to-[#14F195] ",
    };
  };

  return (
    <div className="flex">
      {/* Toggle Button */}
      <button
        className="fixed top-6 left-4 z-50 p-2 bg-gray-950 text-green-400 rounded-full shadow-md md:hidden"
        onClick={toggleSidebar}
      >
        {isSidebarVisible ? (
          <AiOutlineClose size={16} />
        ) : (
          <AiOutlineMenu size={16} />
        )}
      </button>

      {/* Sidebar */}
      {isSidebarVisible && (
        <aside className="bg-gray-950  px-2 text-green-400 w-44 md:w-64 font-mono border-r border-[#5D18BF]/50 overflow-hidden flex flex-col pt-20 shadow-lg shadow-[#5D18BF]/90">
          <div className="text-xl font-bold md:text-2xl py-6 md:py-6 text-center  bg-gray-950 shadow-lg shadow-[#5D18BF]/50">
            <div className="flex space-x-1 items-end justify-center  ">
              <h1 className="text-xl md:text-2xl text-center font-bold   ">
                LoungeAI
              </h1>
              {/* <span className="text-sm">beta</span> */}
            </div>
          </div>
          <ul className="py-4 flex-grow text-sm md:text-lg space-y-1 ">
            <li className="mb-1 ">
              <Link
                to="/"
                className={`block p-2 md:p-4 rounded-lg text-start transition duration-200 ${
                  getButtonBgColor("/").backgroundColor
                } ${getButtonBgColor("/").hoverClass} text-white`}
              >
                <div className="flex items-center space-x-3">
                  <FaLandmark className="w-4 h-4 md:w-5 md:h-5" />
                  <span>Lounge</span>
                </div>
              </Link>
            </li>

            <li className="mb-1 shadow-lg shadow-[#5D18BF]/50">
              <Link
                to="/whale"
                className={`block p-2 md:p-4 rounded-lg text-start transition duration-200 ${
                  getButtonBgColor("/whale").backgroundColor
                } ${
                  getButtonBgColor("/whale").hoverClass
                } pointer-events-none opacity-50 text-white `}
              >
                <div className="flex items-center space-x-3">
                  <HiMiniCheckBadge className="w-4 h-4 md:w-5 md:h-5" />
                  <span>Top 50</span>
                </div>
              </Link>
            </li>

            <li className="mb-1 shadow-lg shadow-[#5D18BF]/50">
              <a
                className="block bg-gray-800/40 p-2 md:p-4 rounded-lg text-start hover:bg-gradient-to-r hover:from-[#5D18BF] hover:via-[#9945FF] hover:to-[#14F195] transition duration-200 hover:text-white"
                href="https://x.com/SolLoungeAI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex items-center space-x-3">
                  <FaXTwitter className="w-4 h-4 md:w-5 md:h-5" />
                  <span>Twitter</span>
                </div>
              </a>
            </li>

            <li className="mb-1 shadow-lg shadow-[#5D18BF]/50">
              <a
                className="block bg-gray-800/40 p-2 md:p-4 rounded-lg text-start hover:bg-gradient-to-r hover:from-[#5D18BF] hover:via-[#9945FF] hover:to-[#14F195] transition duration-200 hover:text-white"
                href="https://t.me/LoungeAI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex items-center space-x-3">
                  <FaTelegramPlane className="w-4 h-4 md:w-5 md:h-5" />
                  <span>Telegram</span>
                </div>
              </a>
            </li>

            <li className="mb-1 shadow-lg shadow-[#5D18BF]/50">
              <a
                className="block bg-gray-800/40 p-2 md:p-4 rounded-lg text-start hover:bg-gradient-to-r hover:from-[#5D18BF] hover:via-[#9945FF] hover:to-[#14F195] transition duration-200 hover:text-white"
                href={`https://dexscreener.com/solana/${Contract_Address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex items-center space-x-3 fill-current">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="current"
                    fill-rule="evenodd"
                    viewBox="0 0 252 300"
                    focusable="false"
                    class="w-4 h-4 md:w-5 md:h-5"
                  >
                    <path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"></path>
                    <path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"></path>
                  </svg>
                  <span>Dexscreener</span>
                </div>
              </a>
            </li>
          </ul>

          {/* Tambahkan tombol musik */}
          <div className="hidden md:flex items-center justify-center pb-2 ">
            <button
              onClick={toggleMusic}
              className="bg-gradient-to-r from-[#5D18BF] via-[#9945FF] to-[#14F195]  
                           p-2 rounded-full text-white hover:opacity-80 transition duration-200"
            >
              {isPlaying ? <Pause size={20} /> : <Play size={20} />}
            </button>
          </div>

          <div className="mt-auto text-xs md:text-[2vh] mb-4 pb-2 space-y-2 shadow-lg shadow-[#5D18BF]/50">
            {/* Harga SOL */}
            {price ? (
              <p className="text-center ">SOL: ${price}</p>
            ) : (
              <p className="flex items-center justify-center space-x-2">
                <span>SOL: </span>
                <span>
                  <Loader className="animate-spin h-5 w-5" />
                </span>
              </p>
            )}
          </div>
        </aside>
      )}
    </div>
  );
};

export default Sidebar;
